import { formatDate } from '@mm-frontend/mithril-ui-kit';
import type { MDropItem, MIconNames, MOptionItem, MTableColumn } from '@mm-frontend/mithril-ui-kit';
import type { RouteRecordRaw } from 'vue-router';

import { AddTransportationOrderActions } from '@/pages/transportation-orders/types.ts';
import { canReadTransportationOrders } from '@/entities/session';
import { readableTransportationStatus, readableTransportationType } from '@/entities/transportation-orders';
import {
  TransportationOrderSortDirectionEnum,
  TransportationOrderSortFieldEnum,
  TransportationOrderStatus,
  TransportationType,
  type TransportationOrder,
  type TransportationOrderFilter,
} from '@/shared/api/generated-api/transportation-orders/data-contracts';
import { RouteName } from '@/shared/config/router';
import { useRouteLoadingStore } from '@/shared/config/stores';
import {
  convertDateToBackend,
  createBeforeEnterRouteAccessCheck,
  formatKopeckToRuble,
  removeUTC,
  secondsToReadableTime,
} from '@/shared/lib/utils';

export const maxTransportationOrderNumber = 1_999_999_999;
export const routeName = 'transportation-orders';
export const route: RouteRecordRaw = {
  path: '',
  name: RouteName.TRANSPORTATION_ORDERS,
  component: () => {
    useRouteLoadingStore().setLoading(true);
    return import('./ui/TransportationOrders.vue');
  },
  beforeEnter: createBeforeEnterRouteAccessCheck(canReadTransportationOrders),
};

export const transportationOrderPageSize = 20;
export const transportationOrdersLimitSize = 500;

export const emptyValue = '—';
const defaultFilter: TransportationOrderFilter = {
  planFirstDepartureAtDate: '',
  planFirstDepartureAtFrom: '00:00',
  planFirstDepartureAtTo: '23:59',
  departureCity: '',
  destinationCity: '',
  transitCity: '',
  departureLogisticPointCode: undefined,
  destinationLogisticPointCode: undefined,
  transitLogisticPointCode: undefined,
  status: [],
  number: undefined,
  carrierId: undefined,
  isRoundTrip: undefined,
  type: [],
  tripName: '',
};

export const getDefaultFilter = (): TransportationOrderFilter => {
  const filter = structuredClone(defaultFilter);
  const today = new Date();
  filter.planFirstDepartureAtDate = convertDateToBackend(today.setDate(today.getDate() + 1));
  return filter;
};

export const tableColumns: MTableColumn<TransportationOrder>[] = [
  {
    label: '№ заказа',
    value: 'number',
  },
  {
    label: 'Наименование',
    value: 'tripName',
    isSortable: true,
  },
  {
    label: 'Пункт отправления',
    value: 'departurePoint',
    customRender: (row) => row.schedule.firstSchedulePoint.logisticPoint?.shortName ?? '',
  },
  {
    label: 'Перевозчик',
    value: 'carrier',
    customRender: (row) => row.carrier?.companyName ?? '',
  },
  {
    label: 'Дата отправления',
    value: 'departureDate',
    customRender: (row) => formatDate(removeUTC(row.schedule.planFirstDepartureAt), 'digital', '—'),
    isSortable: true,
  },
  {
    label: 'Куб.ТС',
    value: 'cargoVolume',
    customRender: (row) => `${row.cargoVolume} м3`,
    isSortable: true,
  },
  {
    label: 'Статус заказа',
    value: 'status',
  },
  {
    label: 'Тип перевозки',
    value: 'type',
    customRender: (row) => readableTransportationType(row.type),
  },
  {
    label: 'Кол-во точек',
    value: 'pointsCount',
    customRender: (row) => String(row.schedule.schedulePointsCount),
    isSortable: true,
  },
  {
    label: 'Время в пути',
    value: 'totalDuration',
    customRender: (row) => secondsToReadableTime(row.schedule.totalDuration),
    isSortable: true,
  },
  {
    label: 'Стоимость',
    value: 'price',
    customRender: (row) => `${formatKopeckToRuble(row.price)}\xA0₽`,
  },
  {
    label: 'Круговой',
    value: 'isRoundTrip',
    customRender: (row) => (row.schedule.isRoundTrip ? 'Да' : 'Нет'),
  },
  {
    label: 'Статус заявки',
    value: 'offerStatus',
  },
];

export const transportationOrdersSortFieldMap: Record<string, TransportationOrderSortFieldEnum> = {
  cargoVolume: TransportationOrderSortFieldEnum.CargoVolume,
  departureDate: TransportationOrderSortFieldEnum.SchedulePlanFirstDepartureAt,
  pointsCount: TransportationOrderSortFieldEnum.ScheduleSchedulePointsCount,
  totalDuration: TransportationOrderSortFieldEnum.ScheduleTotalDuration,
  tripName: TransportationOrderSortFieldEnum.TripName,
};

export const transportationOrdersSortDirectionMap: Record<string, TransportationOrderSortDirectionEnum> = {
  asc: TransportationOrderSortDirectionEnum.Asc,
  desc: TransportationOrderSortDirectionEnum.Desc,
  default: TransportationOrderSortDirectionEnum.Asc,
};

export const tripStatusOptions: MOptionItem[] = [
  {
    title: 'Да',
    id: 1,
  },
  {
    title: 'Нет',
    id: 0,
  },
];

export const transportationTypeOptions: MOptionItem[] = Object.values(TransportationType).map(
  (transportationType: TransportationType) => ({
    title: readableTransportationType(transportationType),
    id: transportationType as string,
  }),
);

export const transportationOrderStatusOptions: MOptionItem[] = Object.values(TransportationOrderStatus).map(
  (transportationOrderStatus: TransportationOrderStatus) => ({
    title: readableTransportationStatus(transportationOrderStatus),
    id: transportationOrderStatus as string,
  }),
);

export const dropList: MDropItem<AddTransportationOrderActions, { label?: string; icon?: MIconNames }>[] = [
  {
    text: 'Добавить заказ на Внутригород',
    action: AddTransportationOrderActions.ADD_INTRACITY_TRANSPORTATION,
    attrs: {
      label: 'Внутригород',
      icon: 'add',
    },
  },
  {
    text: 'Загрузить заказы на Внутригород из Excel',
    action: AddTransportationOrderActions.ADD_FROM_EXCEL,
    attrs: {
      icon: 'document-arrow-up',
    },
  },
  {
    text: 'Добавить заказ на Магистраль',
    action: AddTransportationOrderActions.ADD_LONG_DISTANCE_TRANSPORTATION,
    attrs: {
      label: 'Магистраль',
      icon: 'add',
    },
  },
];
