import type { ErrorEvent, EventHint } from '@sentry/core/build/types/types-hoist/event';
import * as Sentry from '@sentry/vue';
import { storeToRefs } from 'pinia';
import type { App } from 'vue';
import { watch } from 'vue';

import { useDeviceInfoStore, useUserStore } from '@/shared/config/stores';
import { env } from '@/shared/env';

export const withSentry = (app: App): void => {
  if (!env.SENTRY_DSN) {
    return;
  }

  const rate = Number.parseFloat(env.SENTRY_RATE);

  Sentry.init({
    app,
    environment: env.ENVIRONMENT,
    dsn: env.SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration({ router: app.config.globalProperties.$router }),
      Sentry.replayIntegration({
        maskAllText: false,
        maskAllInputs: false,
        mask: ['input[type=password]'],
        blockAllMedia: false,
        block: ['.leaflet-tile'],
        maxReplayDuration: 60_000,
      }),
      Sentry.browserSessionIntegration(),
      Sentry.browserProfilingIntegration(),
    ],
    profilesSampleRate: rate,
    replaysOnErrorSampleRate: rate,
    replaysSessionSampleRate: 0,
    tracesSampleRate: rate,
    tracePropagationTargets: [env.SERVER_URI],
    beforeSend: (event: ErrorEvent, _: EventHint) => {
      event.contexts = event.contexts || {};
      event.contexts['User Time'] = {
        'Date Time': new Date(),
        Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };
      return event;
    },
  });

  const { userInfo } = storeToRefs(useUserStore());
  const { fingerprint } = storeToRefs(useDeviceInfoStore());
  watch(
    [userInfo, fingerprint],
    ([newUserInfo, newFingerPrint]) => {
      Sentry.setUser({
        id: newUserInfo.id || undefined,
        username: `${newUserInfo.firstName} ${newUserInfo.lastName}`.trim() || undefined,
        Fingerprint: newFingerPrint || undefined,
      });
    },
    { immediate: true },
  );
};
