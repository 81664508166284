import type { ApiName } from '../api.models';

export const mockPorts: Record<ApiName, string> = {
  carriers: '4009',
  drivers: '4010',
  fleets: '4011',
  'logistic-points': '4013',
  'transportation-orders': '4014',
  'documents-generator': '4015',
};
