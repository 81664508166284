import { formatDate, readableEnum } from '@mm-frontend/mithril-ui-kit';
import type { MIconColor, MIconNames, MOptionItem, MTableColumn } from '@mm-frontend/mithril-ui-kit';
import type { RouteRecordRaw } from 'vue-router';

import { readableTransportationType } from '@/entities/transportation-orders';
import { readableTripEvent } from '@/entities/trip';
import type { Trip, TripFilter } from '@/shared/api/generated-api/transportation-orders/data-contracts';
import {
  ProblemType,
  TripDriverStatus,
  TripSortDirectionEnum,
  TripSortFieldEnum,
} from '@/shared/api/generated-api/transportation-orders/data-contracts';
import { oneDay } from '@/shared/config/date';
import { RouteName } from '@/shared/config/router';
import { useRouteLoadingStore } from '@/shared/config/stores';
import { removeTimezoneOffset, removeUTC, replaceCommaInDate } from '@/shared/lib/utils';

import { isCompleted } from './lib';
import type { TripLocalFilter } from './types';

export const maxTripNumber = 1_999_999_999;

export const route: RouteRecordRaw = {
  path: 'monitoring',
  name: RouteName.MONITORING,
  component: () => {
    useRouteLoadingStore().setLoading(true);
    return import('./ui/Monitoring.vue');
  },
};
export const tripsPageSize = 20;
const defaultFilter: TripLocalFilter = {
  startDate: undefined,
  endDate: undefined,
  departureCity: '',
  departureLogisticPoint: undefined,
  type: [],
  status: [],
  carrier: undefined,
  registrationNumber: '',
  number: undefined,
  transitCity: '',
  transitLogisticPoint: undefined,
  waitingEvent: undefined,
  isRoundTrip: undefined,
  destinationCity: '',
  destinationLogisticPoint: undefined,
  transportationOrderTripName: '',
  problemType: undefined,
};

export const getDefaultFilter = (): TripLocalFilter => {
  const filter = structuredClone(defaultFilter);
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  filter.startDate = today;
  return filter;
};

export const createFilterFromLocal = (localFilter: TripLocalFilter): TripFilter => {
  const localFilterEndDatePlusOne = localFilter.endDate ? new Date(localFilter.endDate.getTime() + oneDay) : undefined;
  return {
    tripNumber: localFilter.number,
    tripStatus: localFilter.status,
    vehicleRegNumber: localFilter.registrationNumber,
    expectedEventEventType: localFilter.waitingEvent,
    schedulePlanFirstDepartureAtFrom: localFilter.startDate
      ? removeTimezoneOffset(localFilter.startDate).toISOString()
      : undefined,
    schedulePlanFirstDepartureAtTo: localFilterEndDatePlusOne
      ? removeTimezoneOffset(localFilterEndDatePlusOne).toISOString()
      : undefined,
    scheduleDepartureCity: localFilter.departureCity,
    scheduleDestinationCity: localFilter.destinationCity,
    scheduleTransitCity: localFilter.transitCity,
    transportationOrderCarrierId: localFilter.carrier?.id,
    scheduleIsRoundTrip: localFilter.isRoundTrip === undefined ? undefined : Boolean(localFilter.isRoundTrip),
    scheduleDepartureLogisticPointCode: localFilter.departureLogisticPoint?.shortName,
    scheduleDestinationLogisticPointCode: localFilter.destinationLogisticPoint?.shortName,
    scheduleTransitLogisticPointCode: localFilter.transitLogisticPoint?.shortName,
    transportationOrderType: localFilter.type,
    transportationOrderTripName: localFilter.transportationOrderTripName,
    isOwnCarrier:
      localFilter.isOwnCarrier === undefined || localFilter.isOwnCarrier.length > 1
        ? undefined
        : Boolean(localFilter.isOwnCarrier[0]),
    isLateProblem: localFilter.problemType?.includes(ProblemType.PROBLEM_TYPE_DELAY) ? true : undefined,
    isDeliveryProblem: localFilter.problemType?.includes(ProblemType.PROBLEM_TYPE_DELIVERY_PROBLEM) ? true : undefined,
  };
};

export const tripIsRoundOptions: MOptionItem[] = [
  {
    title: 'Да',
    id: 1,
  },
  {
    title: 'Нет',
    id: 0,
  },
];

export const emptyValue = '—';

export const tableColumns: MTableColumn<Trip>[] = [
  {
    label: '',
    value: 'problematic',
  },
  {
    label: '№ рейса',
    value: 'number',
  },
  {
    label: 'Водитель',
    value: 'driver',
  },
  {
    label: 'Наименование',
    value: 'tripName',
    isSortable: true,
  },
  {
    label: 'Ожидаемое событие',
    value: 'event',
    customRender: (row) =>
      row.expectedEvent && !isCompleted(row) ? readableTripEvent(row.expectedEvent.eventType) : emptyValue,
  },
  {
    label: 'Лог. пункт события',
    value: 'eventLogisticPoint',
    customRender: (row) =>
      row.expectedEvent?.schedulePoint.logisticPoint?.shortName && !isCompleted(row)
        ? row.expectedEvent.schedulePoint.logisticPoint.shortName
        : emptyValue,
  },
  {
    label: 'Осталось до плана',
    value: 'timeLeft',
    isSortable: true,
  },
  {
    label: 'Плановое время',
    value: 'timePlanned',
    customRender: (row) =>
      isCompleted(row)
        ? emptyValue
        : replaceCommaInDate(formatDate(removeUTC(row.expectedEvent?.planTimeAt), 'digital', '—')),
  },
  {
    label: 'Действие по событию',
    value: 'eventAction',
  },
  {
    label: 'Утилизация',
    value: 'utilization',
    customRender: (row) => (row.utilization ? `${row.utilization}%` : emptyValue),
  },
  {
    label: 'ТС',
    value: 'vehicle',
  },
  {
    label: 'Перевозчик',
    value: 'carrier',
    customRender: (row) => row.transportationOrder?.carrier?.companyName ?? emptyValue,
  },
  {
    label: 'Статус рейса',
    value: 'status',
  },
  {
    label: 'Начальный пункт',
    value: 'departurePoint',
    customRender: (row) => row.transportationOrder?.schedule.firstSchedulePoint.logisticPoint?.shortName ?? emptyValue,
  },
  {
    label: 'План. дата отправления НП',
    value: 'departurePlannedDate',
    customRender: (row) =>
      replaceCommaInDate(formatDate(removeUTC(row.transportationOrder?.schedule.planFirstDepartureAt), 'digital', '—')),
    isSortable: true,
  },
  {
    label: 'Факт. дата отправления НП',
    value: 'departureFactDate',
    customRender: (row) => replaceCommaInDate(formatDate(removeUTC(row.factFirstDepartureAt), 'digital', '—')),
    isSortable: true,
  },
  {
    label: 'План. дата прибытия КП',
    value: 'arrivalPlannedDate',
    customRender: (row) =>
      replaceCommaInDate(formatDate(removeUTC(row.transportationOrder?.schedule.planLastArrivalAt), 'digital', '—')),
    isSortable: true,
  },
  {
    label: 'Факт. дата прибытия КП',
    value: 'arrivalFactDate',
    customRender: (row) => replaceCommaInDate(formatDate(removeUTC(row.factLastArrivalAt), 'digital', '—')),
    isSortable: true,
  },
  {
    label: 'Вид перевозки',
    value: 'transportationType',
    customRender: (row) =>
      row.transportationOrder ? readableTransportationType(row.transportationOrder.type) : emptyValue,
  },
  {
    label: 'Круговой рейс',
    value: 'isRoundTrip',
    customRender: (row) => (row.transportationOrder?.schedule.isRoundTrip ? 'Да' : 'Нет'),
  },
  {
    label: 'Плановая куб. ТС',
    value: 'vehicleCapacity',
    customRender: (row) =>
      row.transportationOrder?.cargoVolume ? `${row.transportationOrder.cargoVolume} м3` : emptyValue,
    isSortable: true,
  },
];

export const tripSortFieldMap: Record<string, TripSortFieldEnum> = {
  timeLeft: TripSortFieldEnum.TripEventPlanTimeAt,
  departurePlannedDate: TripSortFieldEnum.TransportationOrderPlanFirstDepartureAt,
  departureFactDate: TripSortFieldEnum.TripFactFirstDepartureAt,
  arrivalPlannedDate: TripSortFieldEnum.TransportationOrderPlanLastArrivalAt,
  arrivalFactDate: TripSortFieldEnum.TripFactLastArrivalAt,
  vehicleCapacity: TripSortFieldEnum.TransportationOrderCargoVolume,
  tripName: TripSortFieldEnum.TransportationOrderTripName,
};

export const tripSortDirectionMap: Record<string, TripSortDirectionEnum> = {
  asc: TripSortDirectionEnum.Asc,
  desc: TripSortDirectionEnum.Desc,
  default: TripSortDirectionEnum.Asc,
};

export const readableTripDriverStatus = readableEnum<TripDriverStatus, string, true>({
  [TripDriverStatus.TRIP_DRIVER_STATUS_ASSIGNED]: 'Рейс ожидает подтверждения',
  [TripDriverStatus.TRIP_DRIVER_STATUS_ACCEPTED]: 'Водитель подтвердил рейс',
  [TripDriverStatus.TRIP_DRIVER_STATUS_DECLINED]: 'Водитель отклонил рейс',
});

export const iconFromTripDriverStatus = readableEnum<TripDriverStatus, MIconNames, true>({
  [TripDriverStatus.TRIP_DRIVER_STATUS_ASSIGNED]: 'person-clock',
  [TripDriverStatus.TRIP_DRIVER_STATUS_ACCEPTED]: 'person-available',
  [TripDriverStatus.TRIP_DRIVER_STATUS_DECLINED]: 'person-delete',
});

export const iconColorFromTripDriverStauts = readableEnum<TripDriverStatus, MIconColor, true>({
  [TripDriverStatus.TRIP_DRIVER_STATUS_ASSIGNED]: 'caution',
  [TripDriverStatus.TRIP_DRIVER_STATUS_ACCEPTED]: 'positive',
  [TripDriverStatus.TRIP_DRIVER_STATUS_DECLINED]: 'negative',
});
