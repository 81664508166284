import type { MOptionItem, MTableColumn } from '@mm-frontend/mithril-ui-kit';
import type { RouteRecordRaw } from 'vue-router';

import { readableBodyType, readableVehicleType } from '@/entities/model';
import { canReadModels } from '@/entities/session';
import {
  BodyType,
  VehicleType,
  type GetModelsParams,
  type Model,
} from '@/shared/api/generated-api/fleets/data-contracts';
import { RouteName } from '@/shared/config/router';
import { useRouteLoadingStore } from '@/shared/config/stores';
import { createBeforeEnterRouteAccessCheck } from '@/shared/lib/utils';

export const route: RouteRecordRaw = {
  path: 'models',
  name: RouteName.MODELS,
  component: () => {
    useRouteLoadingStore().setLoading(true);
    return import('./ui/Models.vue');
  },
  beforeEnter: createBeforeEnterRouteAccessCheck(canReadModels),
};

export const tableColumns: MTableColumn<Model>[] = [
  {
    label: '',
    value: 'icon',
  },
  {
    label: 'Марка',
    value: 'brand',
  },
  {
    label: 'Модель',
    value: 'model',
  },
  {
    label: 'Тип ТС',
    value: 'vehicleType',
    customRender: (data) => readableVehicleType(data.vehicleType),
  },
  {
    label: 'Тип кузова',
    value: 'bodyType',
    customRender: (data) => (data.bodyType ? readableBodyType(data.bodyType) : ''),
  },
  {
    label: 'Грузоподъемность, т',
    value: 'loadCapacity',
  },
  {
    label: 'Кубатура, м3',
    value: 'cargoSpace',
  },
  {
    label: '',
    value: 'action',
    isRequired: true,
  },
];

export const defaultFilter: GetModelsParams = {
  brand: '',
  model: '',
  vehicleTypes: [],
  bodyTypes: [],
};

export const vehicleTypeOptions: MOptionItem[] = Object.values(VehicleType).map((vehicleType: VehicleType) => ({
  title: readableVehicleType(vehicleType),
  id: vehicleType,
}));

export const bodyTypeOptions: MOptionItem[] = Object.values(BodyType).map((bodyType) => ({
  title: readableBodyType(bodyType),
  id: bodyType,
}));

export const MODELS_PER_PAGE_SIZE = 50;
