/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  DeleteData,
  DeployData,
  FillingData,
  FillingParams,
  GetListData,
  GetListParams,
  HttpMethod,
  ReportDeploymentCommand,
  ReportFillingCommand,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export const DocumentsRoutesRegExp = {
  getList: {
    regExp: '^\\/api\\/v1\\/template$',
    method: HttpMethod.GET,
  },
  deploy: {
    regExp: '^\\/api\\/v1\\/template$',
    method: HttpMethod.POST,
  },
  filling: {
    regExp: '^\\/api\\/v1\\/report\\/\\d+$',
    method: HttpMethod.POST,
  },
  delete: {
    regExp: '^\\/api\\/v1\\/template\\/\\d+$',
    method: HttpMethod.DELETE,
  },
};

export class Documents<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Documents
   * @name GetList
   * @summary Получение списка шаблонов (постраничное)
   * @request GET:/api/v1/template
   * @secure
   */
  getList = (query: GetListParams, params: RequestParams = {}) =>
    this.request<GetListData, any>({
      path: `/api/v1/template`,
      method: 'GET',
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Documents
   * @name Deploy
   * @summary Деплой шаблона
   * @request POST:/api/v1/template
   * @secure
   */
  deploy = (data: ReportDeploymentCommand, params: RequestParams = {}) =>
    this.request<DeployData, any>({
      path: `/api/v1/template`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.FormData,
      ...params,
    });
  /**
   * No description
   *
   * @tags Documents
   * @name Filling
   * @summary Получение документа из шаблона
   * @request POST:/api/v1/report/{templateKey}
   * @secure
   */
  filling = ({ templateKey, ...query }: FillingParams, data: ReportFillingCommand, params: RequestParams = {}) =>
    this.request<FillingData, any>({
      path: `/api/v1/report/${templateKey}`,
      method: 'POST',
      query: query,
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Documents
   * @name Delete
   * @summary Удаление шаблона
   * @request DELETE:/api/v1/template/{templateId}
   * @secure
   */
  delete = (templateId: string, params: RequestParams = {}) =>
    this.request<DeleteData, any>({
      path: `/api/v1/template/${templateId}`,
      method: 'DELETE',
      secure: true,
      ...params,
    });
}
