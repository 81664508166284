/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** Перевозчик */
export interface Carrier {
  /**
   * Уникальный идентификатор перевозчика
   * @format uuid
   * @example 123e4567-e89b-12d3-a456-426614174000
   */
  id: string;
  /**
   * Название перевозчика
   * @example ООО 'Рога и Копыта'
   */
  companyName: string;
  /**
   * ИНН перевозчика
   * @example 7712345678
   */
  inn?: string;
  /** Статус перевозчика */
  status?: CarrierStatus;
  /** Адрес */
  legalAddress?: Address;
  /** Типы услуг перевозчика */
  serviceTypes?: ServiceType[];
  /**
   * признак собственности перевозчика
   * @example true
   */
  isOwn?: boolean;
}

/** Перевозчик с подробными данными */
export interface CarrierAggregate {
  /**
   * Уникальный идентификатор перевозчика
   * @format uuid
   * @example 123e4567-e89b-12d3-a456-426614174000
   */
  id: string;
  /** Юридическая форма - ООО или ИП или Самозанятый */
  legalForm: LegalForm;
  /**
   * Название перевозчика
   * @example ООО 'Рога и Копыта'
   */
  companyName: string;
  /**
   * признак собственности перевозчика
   * @example true
   */
  isOwn: boolean;
  /** Статус перевозчика */
  status?: CarrierStatus;
  /**
   * ИНН перевозчика
   * @example 7712345678
   */
  inn?: string;
  /**
   * КПП перевозчика
   * @example 771201001
   */
  kpp?: string;
  /**
   * ОГРН перевозчика
   * @example 1027700132195
   */
  ogrn?: string;
  /** Адрес */
  legalAddress?: Address;
  /**
   * Идентификатор юридического адреса
   * @format uuid
   * @example 123e4567-e89b-12d3-a456-426614174001
   */
  legalAddressId?: string;
  /**
   * Флаг совпадения фактического и юридического адресов
   * @example false
   */
  isSamePhysicalAddress: boolean;
  /** Адрес */
  physicalAddress?: Address;
  /**
   * Идентификатор фактического адреса
   * @format uuid
   * @example 123e4567-e89b-12d3-a456-426614174002
   */
  physicalAddressId?: string;
  /**
   * Флаг совпадения юридического и почтового адресов
   * @example true
   */
  isSameMailingAddress: boolean;
  /** Адрес */
  mailingAddress?: Address;
  /**
   * Идентификатор почтового адреса
   * @format uuid
   * @example 123e4567-e89b-12d3-a456-426614174003
   */
  mailingAddressId?: string;
  /**
   * Телефон
   * @example +7 999 123-45-67
   */
  phone?: string;
  /**
   * Электронная почта
   * @example info@rogaikopyta.ru
   */
  email?: string;
  contactPersons?: ContactPerson[];
  contracts?: Contract[];
}

/**
 * Юридическая форма - ООО или ИП или Самозанятый
 * @example "LEGAL_ENTITY"
 */
export enum LegalForm {
  INDIVIDUAL_ENTREPRENEUR = 'INDIVIDUAL_ENTREPRENEUR',
  LEGAL_ENTITY = 'LEGAL_ENTITY',
  SELF_EMPLOYED = 'SELF_EMPLOYED',
}

/**
 * Статус перевозчика
 * @example "READY"
 */
export enum CarrierStatus {
  NOT_READY = 'NOT_READY',
  READY = 'READY',
}

/**
 * Тип услуги
 * @example "INTRACITY_TRANSPORTATION"
 */
export enum ServiceType {
  COURIER_TRANSPORTATION = 'COURIER_TRANSPORTATION',
  INTRACITY_TRANSPORTATION = 'INTRACITY_TRANSPORTATION',
  LONG_DISTANCE_TRANSPORTATION = 'LONG_DISTANCE_TRANSPORTATION',
}

/**
 * Статус контракта
 * @example "APPROVED"
 */
export enum ContractStatus {
  APPROVED = 'APPROVED',
  DRAFT = 'DRAFT',
  ON_APPROVAL = 'ON_APPROVAL',
  REJECTED = 'REJECTED',
  SENT_FOR_CLARIFICATION = 'SENT_FOR_CLARIFICATION',
}

/**
 * Состояние договора
 * @example "STATE_DRAFT"
 */
export enum ContractState {
  STATE_DRAFT = 'STATE_DRAFT',
  STATE_SENT_FOR_SIGNING_COUNTERPARTY = 'STATE_SENT_FOR_SIGNING_COUNTERPARTY',
  STATE_SENT_FOR_SIGNING_ORGANIZATION = 'STATE_SENT_FOR_SIGNING_ORGANIZATION',
  STATE_SIGNED = 'STATE_SIGNED',
  STATE_IN_PROGRESS = 'STATE_IN_PROGRESS',
  STATE_COMPLETED = 'STATE_COMPLETED',
  STATE_TERMINATED = 'STATE_TERMINATED',
  STATE_SUSPENDED = 'STATE_SUSPENDED',
  STATE_SENT_FOR_CLARIFICATION = 'STATE_SENT_FOR_CLARIFICATION',
}

/**
 * Тип договора
 * @example "TYPE_NO_FUNDS"
 */
export enum ContractType {
  TYPE_NO_FUNDS = 'TYPE_NO_FUNDS',
  TYPE_ADDITIONAL_AGREEMENT = 'TYPE_ADDITIONAL_AGREEMENT',
  TYPE_MAIN_WITH_AMOUNT = 'TYPE_MAIN_WITH_AMOUNT',
  TYPE_FRAMEWORK = 'TYPE_FRAMEWORK',
  TYPE_APPENDIX = 'TYPE_APPENDIX',
  TYPE_INVOICE_CONTRACT = 'TYPE_INVOICE_CONTRACT',
}

/** Адрес */
export interface Address {
  /**
   * Уникальный идентификатор адреса
   * @format uuid
   * @example 123e4567-e89b-12d3-a456-426614174000
   */
  id: string;
  /**
   * Строка адреса
   * @example Россия, Москва, ул. Тверская, 7
   */
  fullAddress?: string;
  /**
   * Код страны
   * @example RU
   */
  countryCode?: string;
  /**
   * Регион
   * @example Московская область
   */
  region?: string;
  /**
   * Район
   * @example Тверской район
   */
  district?: string;
  /**
   * Город
   * @example Москва
   */
  city?: string;
  /**
   * Населенный пункт
   * @example Мытищи
   */
  settlement?: string;
  /**
   * Улица
   * @example ул. Тверская
   */
  street?: string;
  /**
   * Здание
   * @example 7
   */
  building?: string;
  /**
   * Корпус
   * @example 1
   */
  corpus?: string;
  /**
   * Квартира
   * @example 2
   */
  apartment?: string;
  /**
   * Индекс
   * @example 125009
   */
  index?: string;
  /** Тип адреса */
  type?: AddressType;
}

/**
 * Тип адреса
 * @example "LEGAL"
 */
export enum AddressType {
  LEGAL = 'LEGAL',
  MAILING = 'MAILING',
  PHYSICAL = 'PHYSICAL',
}

/** Договор */
export interface Contract {
  /**
   * Уникальный идентификатор контракта
   * @format uuid
   * @example 123e4567-e89b-12d3-a456-426614174000
   */
  id: string;
  /**
   * Уникальный идентификатор контракта на стороне 1с
   * @format uuid
   * @example 123e4567-e89b-12d3-a456-426614174000
   */
  externalId?: string;
  /**
   * Идентификатор перевозчика
   * @format uuid
   * @example 123e4567-e89b-12d3-a456-426614174000
   */
  carrierId: string;
  /**
   * Номер контракта
   * @example 1234567890
   */
  number?: string;
  /**
   * Название контракта
   * @example Контракт No1234567890
   */
  name: string;
  /** Тип услуг перевозчика */
  serviceType?: ServiceType[];
  /**
   * Дата контракта
   * @format date
   * @example 2020-01-01T00:00:00.000Z
   */
  contractDate?: string;
  /**
   * Действительно с
   * @format date
   * @example 2020-01-01T00:00:00.000Z
   */
  validFrom?: string;
  /**
   * Действительно по
   * @format date
   * @example 2020-01-01T00:00:00.000Z
   */
  validTo?: string;
  /**
   * Валюта
   * @example RUB
   */
  currency: string;
  /**
   * Процент НДС
   * @example 20
   */
  vatPercentage: number;
  /**
   * НДС не предусмотрен
   * @example true
   */
  isNotVat: boolean;
  /**
   * НДС включен в сумму
   * @example false
   */
  isVatInAmount: boolean;
  /** Статус контракта */
  status?: ContractStatus;
  /** Состояние договора */
  state: ContractState;
  /** Тип договора */
  type: ContractType;
  /**
   * Идентификатор основного договора
   * @format uuid
   * @example 123e4567-e89b-12d3-a456-426614174000
   */
  mainAgreementId?: string;
  /**
   * Тип оплаты
   * @example PREPAYMENT
   */
  paymentType: string;
}

/** Контактное лицо */
export interface ContactPerson {
  /**
   * Уникальный идентификатор контактного лица
   * @format uuid
   * @example 123e4567-e89b-12d3-a456-426614174000
   */
  id: string;
  /**
   * Идентификатор перевозчика
   * @format uuid
   * @example 123e4567-e89b-12d3-a456-426614174000
   */
  carrierId: string;
  /**
   * Полное имя контактного лица
   * @example Иванов И.И.
   */
  fullName?: string;
  /**
   * Должность контактного лица
   * @example Главный бухгалтер
   */
  position?: string;
  /**
   * Телефон контактного лица
   * @example +7 999 123-45-67
   */
  phone?: string;
  /**
   * Электронная почта контактного лица
   * @example info@rogaikopyta.ru
   */
  email?: string;
}

/** Страница */
export interface Page {
  /**
   * Количество элементов на странице
   * @min 0
   * @max 500
   * @example 10
   */
  size: number;
  /**
   * Номер страницы
   * @min 1
   * @example 4
   */
  number: number;
}

/** Постраничная навигация */
export interface Pagination {
  /** Страница */
  self: Page;
  /** Страница */
  first: Page;
  /** Страница */
  prev?: Page;
  /** Страница */
  next?: Page;
  /** Страница */
  last: Page;
  /**
   * Общее количество элементов
   * @min 0
   * @example 100
   */
  size: number;
}

/** Error response. */
export interface GenericError {
  /** Код ошибки */
  code: ErrorCode;
  /**
   * Заголовок
   * @example Ошибка списания
   */
  title: string;
  /**
   * Подробное описание ошибки
   * @example Error message
   */
  detail: string;
  /**
   * Текст ошибки для команды разработки
   * @example Error message
   */
  debugMessage: string;
}

/** Возврат ошибки запроса */
export interface GenericBadRequestError {
  /** Код ошибки */
  code: ErrorCode;
  /**
   * Заголовок
   * @example Ошибка создания автомобиля
   */
  title: string;
  /**
   * Подробное описание ошибки
   * @example При попытке создать автомобиль, обнаружены ошибки. Попробуйте исправить их и попробовать снова.
   */
  detail: string;
  /**
   * Текст ошибки для команды разработки
   * @example Field: vehicle.model, message: не указана модель автомобиля
   */
  debugMessage: string;
  /** Список ошибок валидации */
  validationErrors: ValidationErrors;
}

/** Код ошибки */
export enum ErrorCode {
  Value400100 = 400100,
  Value403100 = 403100,
  Value404100 = 404100,
  Value422100 = 422100,
  Value500100 = 500100,
}

/** Список ошибок валидации */
export type ValidationErrors = ValidationError[];

/** Ошибка валидации */
export interface ValidationError {
  /**
   * Наименование поля
   * @example ID
   */
  fieldName: string;
  /**
   * Сообщение с ошибкой валидации
   * @example Укажите ID в формате UUID
   */
  message: string;
}

export interface GetCarriersParams {
  /**
   * Наименование перевозчика
   * @example ГрузТрансПеревозки
   */
  companyName?: string;
  /**
   * ИНН перевозчика
   * @example 230104356234
   */
  inn?: string;
  /**
   * Тип услуг перевозчика
   * @example ["INTRACITY_TRANSPORTATION","LONG_DISTANCE_TRANSPORTATION"]
   */
  serviceTypes?: ServiceType[];
  /**
   * Статус перевозчика
   * @example ["NOT_READY","READY"]
   */
  carrierStatuses?: CarrierStatus[];
  /**
   * Собственный перевозчик
   * @example true
   */
  isOwn?: boolean;
  /** Страница */
  page?: Page;
}

export enum HttpMethod {
  GET = 'get',
  POST = 'post',
  PUT = 'put',
  PATCH = 'patch',
  DELETE = 'delete',
}
