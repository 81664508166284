<template>
  <m-typography
    class="point-number"
    color="inverted-primary"
    >{{ props.number }}</m-typography
  >
</template>
<script setup lang="ts">
import { MTypography } from '@mm-frontend/mithril-ui-kit';

const props = defineProps<{
  number: number;
}>();
</script>
<style scoped lang="scss">
.point-number {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 20px;
  border-radius: 6px;
  background: var(--blue-50);
}
</style>
