import type { MDropItem, MOptionItem, MTableColumn } from '@mm-frontend/mithril-ui-kit';
import type { RouteRecordRaw } from 'vue-router';

import { canReadVehicles } from '@/entities/session';
import { readableVehicleStatus } from '@/entities/vehicle';
import {
  InteractionType,
  VehicleStatus,
  type GetVehiclesParams,
  type Vehicle,
} from '@/shared/api/generated-api/fleets/data-contracts';
import { RouteName } from '@/shared/config/router';
import { useRouteLoadingStore } from '@/shared/config/stores';
import { createBeforeEnterRouteAccessCheck } from '@/shared/lib/utils';

export const route: RouteRecordRaw = {
  path: 'vehicles',
  name: RouteName.VEHICLES,
  component: () => {
    useRouteLoadingStore().setLoading(true);
    return import('./ui/Vehicles.vue');
  },
  beforeEnter: createBeforeEnterRouteAccessCheck(canReadVehicles),
};

export const dropList: MDropItem<InteractionType>[] = [
  {
    text: 'Собственное ТС',
    action: InteractionType.INTERACTION_TYPE_OWN,
  },
  {
    text: 'Привлеченное ТС',
    action: InteractionType.INTERACTION_TYPE_OUTSOURCE,
  },
];

export const tableColumns: MTableColumn<Vehicle>[] = [
  {
    label: '',
    value: 'icon',
  },
  {
    label: 'Марка',
    value: 'brand',
    customRender: (row): string => row.model?.brand || '',
  },
  {
    label: 'Модель',
    value: 'model',
    customRender: (row): string => row.model?.model || '',
  },
  {
    label: 'Рег номер',
    value: 'regNumber',
  },
  {
    label: 'Год выпуска',
    value: 'manufacturedYear',
  },
  {
    label: 'Перевозчик',
    value: 'carrier',
    customRender: (row): string => row.carriers[0]?.companyName || '',
  },
  {
    label: 'Статус ТС',
    value: 'status',
  },
  {
    label: '',
    value: 'action',
    isRequired: true,
  },
];

export const defaultFilter: GetVehiclesParams = {
  brand: '',
  model: '',
  vehicleTypes: [],
  cargoSpaceMin: undefined,
  cargoSpaceMax: undefined,
  statuses: [],
  manufacturedYear: undefined,
  regNumber: '',
};

export const vehicleStatusesTypeOptions: MOptionItem[] = Object.values(VehicleStatus)
  .filter((t) => t !== VehicleStatus.VEHICLE_STATUS_REPAIR)
  .map((vehicleType: VehicleStatus) => ({
    title: readableVehicleStatus(vehicleType),
    id: vehicleType,
  }));

export const VEHICLES_PER_PAGE_SIZE = 50;
