/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface ReportDeploymentCommand {
  key?: string;
  /** @format binary */
  template?: File;
}

export interface DeploymentDto {
  /** @format uuid */
  id: string;
  key: string;
  /** @format int32 */
  version?: number;
  /** @format date-time */
  createdAt?: string;
  /** @format date-time */
  updatedAt?: string;
}

export type JsonNode = object;

export interface ReportFillingCommand {
  reportingData?: any[];
  parameters?: Record<string, object>;
}

export interface GetListParams {
  /** @format int32 */
  page?: number;
  /** @format int32 */
  size?: number;
  sort?: string[];
}

export type GetListData = DeploymentDto[];

export type DeployData = DeploymentDto;

export interface FillingParams {
  /** @format int32 */
  ver?: number;
  templateKey: string;
}

/** @format binary */
export type FillingData = File;

export type DeleteData = object;

export enum HttpMethod {
  GET = 'get',
  POST = 'post',
  PUT = 'put',
  PATCH = 'patch',
  DELETE = 'delete',
}
