import { readableEnum } from '@mm-frontend/mithril-ui-kit';
import type { MOptionItem, MStatusDotColor } from '@mm-frontend/mithril-ui-kit';

import {
  CarrierStatus,
  ContractState,
  ContractStatus,
  ContractType,
  LegalForm,
  ServiceType,
} from '@/shared/api/generated-api/carriers/data-contracts.ts';

import { TripCarrierType } from './types';

export const readableCarrierStatusShort = readableEnum<CarrierStatus>({
  [CarrierStatus.READY]: 'Готов к участию',
  [CarrierStatus.NOT_READY]: 'Не готов к участию',
});

export const readableCarrierStatusLong = readableEnum<CarrierStatus>({
  [CarrierStatus.READY]: 'Готов к участию в перевозках',
  [CarrierStatus.NOT_READY]: 'Не готов к участию в перевозках',
});

export const carrierStatusDotColorMap: Record<CarrierStatus, MStatusDotColor> = {
  [CarrierStatus.READY]: 'green',
  [CarrierStatus.NOT_READY]: 'red',
};

export const readableServiceType = readableEnum<ServiceType>({
  [ServiceType.COURIER_TRANSPORTATION]: 'Курьерские перевозки',
  [ServiceType.INTRACITY_TRANSPORTATION]: 'Внутригородские перевозки',
  [ServiceType.LONG_DISTANCE_TRANSPORTATION]: 'Магистральная перевозка',
});

export const readableLegalForm = readableEnum<LegalForm>({
  [LegalForm.LEGAL_ENTITY]: 'Юридическое лицо',
  [LegalForm.INDIVIDUAL_ENTREPRENEUR]: 'Индивидуальный предприниматель',
  [LegalForm.SELF_EMPLOYED]: 'Самозанятый',
});

export const contractStateDotColorMap: Record<ContractState, MStatusDotColor> = {
  [ContractState.STATE_COMPLETED]: 'green',
  [ContractState.STATE_DRAFT]: 'gray',
  [ContractState.STATE_TERMINATED]: 'red',
  [ContractState.STATE_IN_PROGRESS]: 'persian',
  [ContractState.STATE_SENT_FOR_CLARIFICATION]: 'persian',
  [ContractState.STATE_SENT_FOR_SIGNING_COUNTERPARTY]: 'persian',
  [ContractState.STATE_SENT_FOR_SIGNING_ORGANIZATION]: 'persian',
  [ContractState.STATE_SIGNED]: 'green',
  [ContractState.STATE_SUSPENDED]: 'orange',
};

export const readableContractState = readableEnum<ContractState>({
  [ContractState.STATE_COMPLETED]: 'Исполнен',
  [ContractState.STATE_DRAFT]: 'Черновик',
  [ContractState.STATE_TERMINATED]: 'Расторгнут',
  [ContractState.STATE_IN_PROGRESS]: 'Исполняется',
  [ContractState.STATE_SENT_FOR_CLARIFICATION]: 'Отправлен на уточнение',
  [ContractState.STATE_SENT_FOR_SIGNING_COUNTERPARTY]: 'Передан на подпись контрагенту',
  [ContractState.STATE_SENT_FOR_SIGNING_ORGANIZATION]: 'Передан на подпись организации',
  [ContractState.STATE_SIGNED]: 'Подписан',
  [ContractState.STATE_SUSPENDED]: 'Приостановлен',
});

export const readableContractType = readableEnum<ContractType>({
  [ContractType.TYPE_ADDITIONAL_AGREEMENT]: 'Доп. соглашение',
  [ContractType.TYPE_APPENDIX]: 'Спецификация, приложение',
  [ContractType.TYPE_FRAMEWORK]: 'Рамочный',
  [ContractType.TYPE_INVOICE_CONTRACT]: 'Счет-договор',
  [ContractType.TYPE_MAIN_WITH_AMOUNT]: 'Основной с суммой',
  [ContractType.TYPE_NO_FUNDS]: 'Безденежный',
});

export const readableContractStatus = readableEnum<ContractStatus>({
  [ContractStatus.APPROVED]: 'Утвержден',
  [ContractStatus.DRAFT]: 'Черновик',
  [ContractStatus.REJECTED]: 'Отклонен',
  [ContractStatus.ON_APPROVAL]: 'На утверждении',
  [ContractStatus.SENT_FOR_CLARIFICATION]: 'Отправлен на уточнение',
});

export const MIN_SEARCH_LENGTH = 3;

export const serviceTypeOptions: MOptionItem[] = Object.values(ServiceType).map((serviceType: ServiceType) => ({
  title: readableServiceType(serviceType),
  id: serviceType,
}));

export const carrierStatusOptions: MOptionItem[] = Object.values(CarrierStatus).map((carrierStatus: CarrierStatus) => ({
  title: readableCarrierStatusShort(carrierStatus),
  id: carrierStatus,
}));

export const CARRIERS_SELECT_PER_PAGE_SIZE = 100;

export const readableTripCarrierType = readableEnum<TripCarrierType>({
  [TripCarrierType.CARRIER_OWN]: 'Собственный парк',
  [TripCarrierType.CARRIER_HIRED]: 'Наемный парк',
});

export const tripCarrierTypeOptions: MOptionItem[] = [
  {
    title: readableTripCarrierType(TripCarrierType.CARRIER_OWN),
    id: 1,
  },
  {
    title: readableTripCarrierType(TripCarrierType.CARRIER_HIRED),
    id: 0,
  },
];
