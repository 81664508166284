import type { MOptionItem, MStatusDotColor } from '@mm-frontend/mithril-ui-kit';
import { formatPhone, readableEnum } from '@mm-frontend/mithril-ui-kit';

import type { Driver } from '@/shared/api/generated-api/transportation-orders/data-contracts';
import {
  ProblemCause,
  ProblemType,
  TripEventEventTypeEnum,
  TripStatus,
} from '@/shared/api/generated-api/transportation-orders/data-contracts';
import { formatName } from '@/shared/lib/format';

import type { TripDriverEdit, TripVehicleEdit } from './types';

export const readableTripStatus = readableEnum<TripStatus>({
  [TripStatus.TRIP_STATUS_CREATED]: 'Создан',
  [TripStatus.TRIP_STATUS_CANCELED]: 'Отменен',
  [TripStatus.TRIP_STATUS_ON_THE_WAY]: 'В пути',
  [TripStatus.TRIP_STATUS_WAITING_FOR_INPUT]: 'Ожидает данных',
  [TripStatus.TRIP_STATUS_WAITING_FOR_LOADING]: 'Ожидает погрузку',
  [TripStatus.TRIP_STATUS_AT_LOG_POINT]: 'На лог пункте',
  [TripStatus.TRIP_STATUS_COMPLETED]: 'Завершен',
});

export const readableTripProblem = readableEnum<ProblemCause>({
  [ProblemCause.PROBLEM_CAUSE_CARRIER_DRIVER_FAULT]: 'Вина ТК: вина водителя',
  [ProblemCause.PROBLEM_CAUSE_SC_NOT_LOADED]: 'Вина СЦ: не отгрузили заказы',
  [ProblemCause.PROBLEM_CAUSE_PICKUP_POINT_PROBLEM]: 'Вина ПВЗ: проблемы в ПВЗ (или закрыт)',
  [ProblemCause.PROBLEM_CAUSE_CARRIER_DRIVER_PARTIAL_LOAD]: 'Вина ТК: водитель не выложил часть груза',
  [ProblemCause.PROBLEM_CAUSE_CARRIER_TRAFFIC]: 'Вина ТК: пробки (дорожные работы, погода)',
  [ProblemCause.PROBLEM_CAUSE_CARRIER_ROAD_ACCIDENT]: 'Вина ТК: ДТП',
  [ProblemCause.PROBLEM_CAUSE_CARRIER_REPLACEMENT_VEHICLE_OR_DRIVER]: 'Вина ТК: замена ТС/водителя',
  [ProblemCause.PROBLEM_CAUSE_CARRIER_LATE_FOR_LOADING]: 'Вина ТК: опоздание на погрузку',
  [ProblemCause.PROBLEM_CAUSE_SC_LATE_LOADING]: 'Вина СЦ: поздно выпустили с погрузки',
  [ProblemCause.PROBLEM_CAUSE_LOGISTIC_INEFFICIENT_ROUTE]: 'Вина логистики: некорректный маршрут/объем',
});

export const readableTripProblemType = readableEnum<ProblemType>({
  [ProblemType.PROBLEM_TYPE_DELAY]: 'Опоздание',
  [ProblemType.PROBLEM_TYPE_DELIVERY_PROBLEM]: 'Проблема с доставкой груза',
});

export const readableTripEvent = readableEnum<TripEventEventTypeEnum>({
  [TripEventEventTypeEnum.TRIP_EVENT_TYPE_ARRIVAL]: 'Прибытие',
  [TripEventEventTypeEnum.TRIP_EVENT_TYPE_DEPARTURE]: 'Отправление',
});

export const colorFromTripStatus = readableEnum<TripStatus, MStatusDotColor>({
  [TripStatus.TRIP_STATUS_CREATED]: 'orange',
  [TripStatus.TRIP_STATUS_CANCELED]: 'red',
  [TripStatus.TRIP_STATUS_ON_THE_WAY]: 'persian',
  [TripStatus.TRIP_STATUS_WAITING_FOR_INPUT]: 'orange',
  [TripStatus.TRIP_STATUS_WAITING_FOR_LOADING]: 'persian',
  [TripStatus.TRIP_STATUS_AT_LOG_POINT]: 'persian',
  [TripStatus.TRIP_STATUS_COMPLETED]: 'green',
});

export const tripStatusOptions: MOptionItem[] = Object.values(TripStatus).map((tripStatus: TripStatus) => ({
  title: readableTripStatus(tripStatus),
  id: tripStatus as string,
}));

export const tripEventOptions: MOptionItem[] = Object.values(TripEventEventTypeEnum).map(
  (tripEvent: TripEventEventTypeEnum) => ({
    title: readableTripEvent(tripEvent),
    id: tripEvent as string,
  }),
);

export const tripProblemOptions: MOptionItem[] = Object.values(ProblemType).map((problem: ProblemType) => ({
  title: readableTripProblemType(problem),
  id: problem as string,
}));

export const getDriverFullName = (driver?: Driver): string => {
  if (!driver) return '—';

  return formatName(driver);
};
export const getDriverNumber = (driver?: Driver): string => {
  if (!driver) return '—';

  return formatPhone(driver.primaryPhoneNumber, { prefix: driver.primaryPhoneCountryCode });
};

export const getDefaultTripDriver = (tripNumber: number, position: number): TripDriverEdit => ({
  tripNumber,
  driverId: '',
  driver: undefined,
  finalSchedulePointId: '',
  position,
  isStarted: false,
});

export const getDefaultTripVehicle = (tripNumber: number, position: number): TripVehicleEdit => ({
  tripNumber,
  vehicleId: '',
  vehicle: undefined,
  finalSchedulePointId: '',
  position,
});
