import { formatDate } from '@mm-frontend/mithril-ui-kit';

import type {
  SchedulePoint,
  ScheduleSegment,
  TransportationOrder,
} from '@/shared/api/generated-api/transportation-orders/data-contracts';
import {
  formatMetrToKilometr,
  getDistance,
  getPluralWord,
  getTimeDiffWithTimezones,
  removeUTC,
  secondsToReadableTime,
} from '@/shared/lib/utils';
import type { DateWithTimezone } from '@/shared/types';

import type { TransitDate } from './types';

/**
 * Вычисляет время между двумя точками в расписании.
 *
 * @param {SchedulePoint[]} points - Массив точек расписания.
 * @param {number} index - Индекс точки
 * @return {number | undefined} Время между точками в секундах.
 */
export const getTimeBetweenPoints = (points: SchedulePoint[] | undefined, index: number): number | undefined => {
  if (index === 0 || !points) return;
  const arrivePoint = points[index];
  const arriveTime: DateWithTimezone | undefined =
    arrivePoint.planArriveAt && arrivePoint.logisticPoint?.address.timezone
      ? { date: arrivePoint.planArriveAt, timezone: arrivePoint.logisticPoint.address.timezone }
      : undefined;
  const departPoint = points[index - 1];
  const departTime: DateWithTimezone | undefined =
    departPoint.planDepartAt && departPoint.logisticPoint?.address.timezone
      ? { date: departPoint.planDepartAt, timezone: departPoint.logisticPoint.address.timezone }
      : undefined;
  if (!departTime || !arriveTime) return;
  return getTimeDiffWithTimezones(arriveTime, departTime);
};

/**
 * Вычисляет время между двумя точками в расписании в читаемом формате HH:MM.
 *
 * @param {SchedulePoint[]} points - Массив точек расписания.
 * @param {number} index - Индекс точки
 * @return {number | undefined} Время между точками в формате HH:MM.
 */
export const getReadableTimeBetweenPoints = (
  points: SchedulePoint[] | undefined,
  index: number,
): string | undefined => {
  if (index === 0 || !points) return;
  let timeBetweenPoints = getTimeBetweenPoints(points, index);
  if (timeBetweenPoints) timeBetweenPoints = timeBetweenPoints - (timeBetweenPoints % 60);
  return timeBetweenPoints ? secondsToReadableTime(timeBetweenPoints, { showSeconds: true }) : '—';
};

/**
 * Вычисляет длину между двумя точками на графике.
 *
 * @param {SchedulePoint[]} point - Массив точек расписания.
 * @param {number} index - Индекс точки.
 * @return {string | undefined} Расстояние между двумя точками в километрах, or '-' if either address is missing.
 */
export const getLengthBetweenTwoPoints = (point: SchedulePoint[] | undefined, index: number): string | undefined => {
  if (index === 0 || !point) return;
  const firstPointAddress = point[index - 1].logisticPoint?.address;
  const secondPointAddress = point[index].logisticPoint?.address;
  if (!firstPointAddress || !secondPointAddress) return '—';

  return formatMetrToKilometr(getDistance(firstPointAddress, secondPointAddress));
};

/**
 * Вычисляет длину между точками на графике. Между каждой точкой расстояние вычисляется с окрглением вверх
 *
 * @param {SchedulePoint[]} points - Массив точек расписания.
 * @return {number} Расстояние между точками в метрах.
 */
export const getLengthBetweenPoints = (points: SchedulePoint[] | undefined): number =>
  (points || []).reduce((distance: number, current: SchedulePoint, index: number, array: SchedulePoint[]) => {
    if (index === 0) return 0;
    const firstPointAddress = array[index - 1].logisticPoint?.address;
    const secondPointAddress = current.logisticPoint?.address;
    if (!firstPointAddress || !secondPointAddress) return 0;
    return distance + Math.ceil(getDistance(secondPointAddress, firstPointAddress));
  }, 0);

export const routePluralText = (points: number = 0): string => {
  return `${points} ${getPluralWord(points, ['пункт', 'пункта', 'пунктов'])}`;
};

export const formatDateToIso = ({ date, time }: TransitDate): string => {
  if (!date || !time) return '';
  const splitTime = time.split(':');
  const timeInMinutes = Number(splitTime[0]) * 60 + Number(splitTime[1]);
  return new Date(date.getTime() + timeInMinutes * 60_000 - date.getTimezoneOffset() * 60_000).toISOString();
};

const emptyTransitDate = { time: '', date: undefined };

export const formatISODateToTransitDate = (dateString?: string): TransitDate => {
  if (!dateString) return { ...emptyTransitDate };

  const date = new Date(dateString);

  if (Number.isNaN(date.getTime())) return { ...emptyTransitDate };

  const time = formatDate(date, 'shortTime');
  date.setHours(0, 0, 0, 0);
  return {
    time,
    date,
  };
};

export const handleApiRequestOrder = (
  order: TransportationOrder,
  options: { isResetNumber?: boolean; needClone?: boolean } = {},
): TransportationOrder => {
  // Сбрасываем нулевой price, чтобы прошла валдиация на бэке, т.к. инпуты у нас number и отдают 0 при пустом
  const formattedOrder: TransportationOrder = options.needClone ? JSON.parse(JSON.stringify(order)) : order;
  if (order.price === 0) {
    formattedOrder.price = undefined;
  }

  if (options.isResetNumber) {
    formattedOrder.number = undefined;
  }

  const temporaryId = '00000000-0000-0000-0000-000000000000';
  // Сбрасываем созданные id на undefined, чтобы прошла валидация на беке
  formattedOrder.schedule.points = order.schedule.points?.map((point) => ({
    ...point,
    id: temporaryId,
  }));

  formattedOrder.schedule.firstSchedulePoint.id = temporaryId;

  return formattedOrder;
};

// ...

export const getShipmentTimeForWarehouse = (cargoVolume: number): number => {
  if (cargoVolume >= 40) return 60;
  if (cargoVolume >= 18) return 35;
  if (cargoVolume >= 9) return 25;
  return 20;
};
export const addShipmentToTime = (time: string, cargoVolume: number, isWarehouse: boolean): TransitDate => {
  const timeWithoutUTC = removeUTC(time);
  if (!timeWithoutUTC) return { ...emptyTransitDate };
  const convertedDate = new Date(timeWithoutUTC);
  convertedDate.setMinutes(convertedDate.getMinutes() + (isWarehouse ? getShipmentTimeForWarehouse(cargoVolume) : 15));
  return formatISODateToTransitDate(convertedDate.toISOString());
};

export const getPluralOrder = (count: number): string =>
  `${count} ${getPluralWord(count, ['заказ', 'заказа', 'заказов'])}`;

export const getSegmentTime = (segment?: ScheduleSegment): string => {
  let transitTime = segment?.planTransitDuration;
  if (transitTime) transitTime = transitTime - (transitTime % 60);
  if (!transitTime) return '—';
  return secondsToReadableTime(transitTime, { showSeconds: true });
};
